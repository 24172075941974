(function (defaults) {
	'use strict';

	defaults.DBK_BASEURL = `https://${window.location.host}/`;

	// DayBack API
	defaults.DBK_API_BASEURL = `${defaults.DBK_BASEURL}api/`;
	defaults.DBK_JOURNEY_URL = `${defaults.DBK_API_BASEURL}journey`;
	defaults.DBK_TRIAL_URL = `${defaults.DBK_API_BASEURL}trial`;
	defaults.DBK_SUBSCRIPTION_URL = `${defaults.DBK_API_BASEURL}subscription`;
	defaults.DBK_TOKENS_URL = `${defaults.DBK_API_BASEURL}tokens`;
	defaults.DBK_STATUS_URL = `${defaults.DBK_API_BASEURL}webhook/status`;

	// Purchase URLs
	defaults.DBK_PURCHASE_URL = 'https://dayback.com/pricing-dev/';
	defaults.DBK_MONTHLY_COMPONENT_ID = '952214';
	defaults.DBK_YEARLY_COMPONENT_ID = '952216';
	defaults.DBK_MONTHLY_ESSENTIALS_COMPONENT_ID = '1659640';
	defaults.DBK_MONTHLY_PLUS_COMPONENT_ID = '1659627';
	defaults.DBK_YEARLY_ESSENTIALS_COMPONENT_ID = '1659629';
	defaults.DBK_YEARLY_PLUS_COMPONENT_ID = '1659558';

	// Firebase
	defaults.FIREBASE_API_KEY = 'AIzaSyC8EurZ1j_IlStL_y-kJMmY1mZumqtVCBo';
	defaults.FIREBASE_API_URL = 'https://backup-sandbox-20782.firebaseio.com/';
	defaults.FIREBASE_AUTH_DOMAIN = 'backup-sandbox-20782.firebaseapp.com';
	defaults.FIREBASE_DATABASE_URL =
		'https://backup-sandbox-20782.firebaseio.com/';
	defaults.FIREBASE_PROJECT_ID = 'backup-sandbox-20782';

	// MixPanel
	defaults.DBK_MIXPANEL_ID_APP = '7b0477dfda3a6f5e1954dd8a59921c09';
	defaults.DBK_MIXPANEL_ID_SHARE = 'dd6c541e1399233b6577ca123077f08f';
})(window._CONFIG);
